export class GridOptions {
  // Grid's actionButtons
  static readonly ACTION_BUTTONS: any[] = [];
  // Grid's editable columns
  static readonly EDITABLE: boolean = false;
  // Grid's export settings
  static readonly EXPORT: boolean = true;
  // Grid's export option variable default
  static readonly EXPORT_FILENAME: string = null;
  // Grid's export option variable to enable checkbox
  static readonly ENABLE_CHECKBOX: boolean = false;
  // Grid buttons
  static readonly GRID_BUTTONS: any[] = [];
  // Grid's header option
  static readonly GRID_HEADER: boolean = false;
  // Grid's number of page links to show
  static readonly PAGE_LINKS: number = 10;
  // Grid's paginator option
  static readonly PAGINATOR: boolean = true;
  // Grid's reorderable columns option
  static readonly REORDERABLE_COLUMNS: boolean = true;
  // Grid's resizable columns
  static readonly RESIZABLE_COLUMNS: boolean = false;
  // Grid's responsive option
  static readonly RESPONSIVE: boolean = true;
  // Grid's rows per page
  static readonly ROWS: number = 10;
  // Grid's rows per page options to show
  static readonly ROWS_PER_PAGE_OPTIONS: number[] = [10, 20, 30];
  // Grid's scrollable option
  static readonly SCROLLABLE: boolean = true;
  // Grid's scroll's default height
  static readonly SCROLL_HEIGHT: string = '50vh';
  // Grid's selection mode default
  static readonly SELECTION_MODE: string = '';
  // Grid's column toggler
  static readonly SHOW_COL_TOGGLER: boolean = true;
  // WIP
  static readonly WIP: boolean = false;
}
