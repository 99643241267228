import { CONSTANTS } from '../../shared/constants';
import { FilterBetween } from './filter-between';
import { FilterWhere } from './filter-where';
import { OrderByFilter } from './orderby-filter';

export class Criteria {
  public whereClause: FilterWhere[];
  public betweenClause: FilterBetween;
  public orderByClause: OrderByFilter[];

  constructor() {
    this.whereClause = <FilterWhere[]>[];
    this.betweenClause = <FilterBetween>{};
    this.orderByClause = <OrderByFilter[]>[];
  }

  /**
   * @description Add a new filter to criteria
   * @param {string} key Key to identify field
   * @param {(number | boolean | string | number[] | string[])} value Value to compare
   * @param {string} [condition='='] Condition to apply
   * @param {string} [operator='and'] Operator to apply
   * @param {boolean} [chain=false] Chain operation
   */
  public addFilterWhere(key: string, value: number | boolean | string | number[] | string[], condition = '=', operator = 'and',
    chain = false) {
    let filter: FilterWhere = <FilterWhere>{};
    filter.field = key;
    filter.condition = condition;
    filter.value = value;
    filter.operator = operator;
    filter.chain = chain;
    this.whereClause.push(filter);
  }

  /**
   * @description Add a new filter for not null values
   * @param {string} key - Field name
   * @param {boolean} [chain=false] - Chain operation
   */
  public addNotNull(key: string, chain: boolean = false): void {
    this.addFilterWhere(key, null, null, CONSTANTS.IS_NOT_NULL, chain);
  }

  /**
   * @description Add a new filter for not null values
   * @param {string} key - Field name
   * @param {boolean} [chain=false] - Chain operation
   */
  public addOrNotNull(key: string, chain: boolean = false): void {
    this.addFilterWhere(key, null, CONSTANTS.IS_NOT_NULL, CONSTANTS.OR, chain);
  }

  /**
   * Add a filter in criteria's betweenClause.
   * @param key - Field's name.
   * @param range - Values between which field's value must be.
   */
  public addFilterBetween(key: string, range: FilterBetween) {
    this.betweenClause[key] = range;
  }

  /**
   * Add a filter in criteria's orderByClause.
   * @param key {string} - Field's name.
   * @param direction {string} - Direction of orderBy. ASC by default
   */
  public addFilterOrderBy(key: string, direction: string = CONSTANTS.SORTING_ORDER): void {
    let filter: OrderByFilter = <OrderByFilter>{};
    filter.field = key;
    filter.direction = direction;
    this.orderByClause.push(filter);
  }

  /**
   * @description Transform criteria to string
   * @return {string} String Filters
   */
  public stringify(): string {
    return JSON.stringify(this.whereClause);
  }

  /**
   * Adds a raw query in criteria's whereClause attribute.
   * @param raw {string} - Raw query to be applied in where clause
   */
  public addRawFilterWhere(raw: string): void {
    let filter: FilterWhere = <FilterWhere>{};
    filter.field = raw;
    filter.operator = "raw";
    this.whereClause.push(filter);
  }
}
