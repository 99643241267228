export class Module {
  id: number;
  name: string;
  description: string;

  constructor() {
    this.id = undefined;
    this.name = '';
    this.description = '';
  }
}
