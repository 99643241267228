import { Account } from '../../catalog/account/account';
import { Profile } from '../profile/profile';
import { Role } from '../role/role';
import { WarehouseAccount } from '../../catalog/warehouse-account/warehouse-account';

export class User {
  id: number;
  accounts: Account[];
  employeeNumber: string;
  isActive: boolean;
  isBlocked: boolean;
  isDefault: boolean;
  name: string;
  nickname: string;
  password: string;
  profile: Profile;
  roles: Role[];
  secondSurname: string;
  surname: string;
  warehouseAccount: WarehouseAccount[];
  accountExport?: any[];
  accessAttempts: number;
  lastPasswordChange: Date;
  isChangePasswordRequired: boolean;
  reason: string;
  traslatedReason: string;

  constructor() {
    this.id = undefined;
    this.name = '';
    this.surname = '';
    this.secondSurname = '';
    this.nickname = '';
    this.password = '';
    this.isActive = false;
    this.isBlocked = false;
    this.employeeNumber = '';
    this.profile = new Profile();
    this.roles = [];
    this.warehouseAccount = [];
    this.accounts = [];
    this.isDefault = null;
    this.accountExport = [];
    this.accessAttempts = null;
    this.lastPasswordChange = null;
    this.isChangePasswordRequired = false;
    this.reason = '';
    this.traslatedReason = '';
  }
}
