import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNil } from 'lodash';
import { Observable } from 'rxjs';

import { Account } from '../../catalog/account/account';
import { ENVIRONMENT } from '../../../environments/environment';
import { HandleError } from '../../utils/utils';
import { PolicyParam } from './policy-param';
import { SessionService } from '../../session/session.service';

@Injectable()
export class PolicyParamService {
  constructor(private http: HttpClient, private sessionService: SessionService) { }

  /**
   * @description Return an array of Parameters.
   * @param {Account} account Account related to params
   * @param {PolicyParam} parameter - paramater object with module related.
   * @return {Observable<Parameter[]>}
   */
  public getParams(account: Account, parameter?: PolicyParam): Observable<PolicyParam[]> {
    let criteria: Object[] = [];
    if (account.id) {
      criteria.push({ field: 'account.id', condition: '=', value: account.id });
    }
    criteria.push({ field: 'parameter.warehouse_id', condition: '=', value: this.sessionService.getUserSession().warehouseId });
    if (parameter) {
      criteria.push({ field: 'module.name', condition: '=', value: parameter.module.name });
      criteria.push({ field: 'parameter.name', condition: '=', value: parameter.name });
    }
    return this.http.get<PolicyParam[]>(ENVIRONMENT.API + '/parameter?criteria=' + JSON.stringify(criteria))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Reurn an array of Policies.
   * @param {Account} account Account related to policy
   * @return {Observable<PolicyParam[]>}
   */
  public getPolicies(account: Account): Observable<PolicyParam[]> {
    let criteria: string = '?criteria=[{"field": "account.id","condition": "=","value": "' + account.id + '"}]';
    return this.http.get<PolicyParam[]>(ENVIRONMENT.API + '/policy' + criteria)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Get a parameter object from the id gived.
   * @param {PolicyParam} parameter Parameter info
   * @return {Observable<PolicyParam>}
   */
  public getParameterById(parameter: PolicyParam): Observable<PolicyParam> {
    return this.http.get<PolicyParam>(ENVIRONMENT.API + '/parameter/' + parameter.id)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Get a parameter object from the id gived.
   * @param {PolicyParam} parameter Parameter info
   * @return {Observable<PolicyParam>}
   */
  public getParameter(parameter: PolicyParam): Observable<PolicyParam> {
    let attributes: string = parameter.module.id + '/' + parameter.name + '?accountId=' + parameter.account.id;
    return this.http.get<PolicyParam>(ENVIRONMENT.API + '/parameter/' + attributes)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Get a policy object from the id gived.
   * @param {PolicyParam} policy Policy info
   * @return {Observable<Response>}
   */
  public getPolicy(policy: PolicyParam): Observable<PolicyParam> {
    return this.http.get<PolicyParam>(ENVIRONMENT.API + '/policy/' + policy.id)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Calls to the service to change the bit is_active by sending as the body the edited param or policy
   * @param {PolicyParam} policyParam Object to be overwritten in the file
   * @return {Observable<string>}
   */
  public setBitActive(policyParam: PolicyParam): Observable<string> {
   let parameter = {
      id: policyParam.id,
      is_active: policyParam.is_active,
      value: policyParam.value
    };
    let session = {
      accountId: 1,
      userId: 1,
      warehouseId: 1
    };
    let body = {
      parameter: parameter,
      session: session
    };
    return this.http.put<string>(ENVIRONMENT.API + '/' + policyParam.type, body)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Update the value of the policy or parameter.
   * @param {PolicyParam} policyParam Policyparam object
   * @return {Observable<string>}
   */
  public setValue(policyParam: PolicyParam): Observable<string> {
    return this.http.put<string>(ENVIRONMENT.API + '/' + policyParam.type, policyParam)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Gets the parameter that match with object
   * @param {PolicyParam} parameter - Parameter to found
   * @returns {Observable<PolicyParam>} - Paramter found
   */
  public getParameterRelatedToUserAccounts(parameter: PolicyParam): Observable<PolicyParam> {
    const module: number | string = !isNil(parameter.module.id)
      ? parameter.module.id : parameter.module.name;
    return this.http.get<PolicyParam>(`${ENVIRONMENT.API}/parameter/user-accounts/${module}/${parameter.name}`)
      .pipe(catchError(HandleError.handleErrorObservable));
  }
}
