import { Component, Input, OnChanges } from '@angular/core';
import { head, isEmpty, isEqual, split } from 'lodash';

@Component({
  selector: 'app-field-wrapper',
  templateUrl: 'field-wrapper.component.html',
  styleUrls: ['field-wrapper.component.css']
})
export class FieldWrapperComponent implements OnChanges {

  @Input() fieldLabel: string;
  @Input() fieldName: string;
  @Input() errors?: string | any;
  @Input() column?: boolean;
  @Input() showErrorsAsList?: boolean;

  public splitedErrors: string[];

  constructor() {
    this.splitedErrors = [];
  }

  /**
   * @description Detect changes and executes the logic
   * @returns {void}
   */
  public ngOnChanges(): void {
    this.splitErrors();
  }

  /**
   * @description Split errors by dot when error is an string
   * @returns {void}
   */
  private splitErrors(): void {
    if (this.showErrorsAsList) {
      if (isEqual(typeof this.errors, 'string')) {
        this.splitedErrors = split(this.errors, '.');
      } else if (this.errors instanceof Array &&
        (isEqual(typeof head(this.errors), 'string') || isEmpty(this.errors))) {
        this.splitedErrors = this.errors;
      }
    }
  }
}
