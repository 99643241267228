import { AuthService } from '../shared/authentication/services/auth.service';
import { Component, OnInit } from '@angular/core';

import { CONSTANTS } from '../shared/constants';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LabelService } from '../administration/label-management/label.service';
import { User } from '../administration/user/user';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public user: User;
  public wepImageUrl: string;
  public welcomeText: string;
  public productText: string;
  public lbl = {
    welcome: this.labelService.labelText('lblWelcome'),
    productText: this.labelService.labelText('productWepDescription'),
    productName: this.labelService.labelText('productWepName')
  };
  constructor(private labelService: LabelService,
    private authService: AuthService,
    private sanitizer: DomSanitizer) {
    this.user = new User;
    this.wepImageUrl = CONSTANTS.WEP_LOGO_URL;
    this.welcomeText = this.lbl.welcome;
  }

  /**
   * @description OnInit component.
   */
  public ngOnInit(): void {
    this.getLoggedUser();
    window.scrollTo(CONSTANTS.ZERO, CONSTANTS.ONE);
  }

  /**
   * @description Get image sanitizated
   * @return {SafeResourceUrl} Found resource
   */
  public getWepImage(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.wepImageUrl);
  }

  /**
   * @description Gets user logged information.
   * @return {void}
   */
   public getLoggedUser(): void {
    this.authService.getUserInfo().subscribe(
      (user: User) => {
        this.user = user;
        this.welcomeText = `¡${this.lbl.welcome} ${this.user.name}!`;
      });
  }
}
