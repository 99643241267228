import { AutoCompleteModule } from 'primeng/autocomplete';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ScfAutocompleteModule } from 'scf-library';

import { AutocompleteWrapperComponent } from './autocomplete-wrapper.component';

@NgModule({
  imports: [AutoCompleteModule, CommonModule, FormsModule, ScfAutocompleteModule, ReactiveFormsModule],
  declarations: [AutocompleteWrapperComponent],
  exports: [AutocompleteWrapperComponent]
})
export class AutocompleteWrapperModule { }
