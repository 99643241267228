import { ScfLanguage } from 'scf-library';

export const LANGUAGES_LIST_MOCK: ScfLanguage[] = [
  {
    _isActive: true,
    _id: '62191fc44bbc8e3890181523',
    _name: 'English',
    _country: 'United States',
    _key: 'en',
    _createdAt: '2022-02-25T18:28:20.978Z',
    _updatedAt: '2022-02-25T20:18:41.692Z'
  },
  {
    _isActive: true,
    _id: '6219396e46ef1f96c055615f',
    _name: 'Español',
    _country: 'México',
    _key: 'es',
    _createdAt: '2022-02-25T20:17:50.200Z',
    _updatedAt: '2022-02-25T20:17:50.200Z'
  }
];
