import { find, isNil, isUndefined } from 'lodash';

import { CONSTANTS } from '../../shared/constants';
import { DateUtil } from '../../utils/utils';
import { Role } from '../role/role';
import { User } from '../user/user';

export class ChangePasswordData {
  public isChangePasswordRequired: boolean;
  public isRequestByUser: boolean;
  public lastPasswordChange: Date;
  public maxLifeDaysOfPassword: number;
  public diffDays: number;
  public daysRemaining: number;
  public passwordUpdateAlertShowed: boolean;
  public isAdminRole: boolean;

  constructor() {
    this.isChangePasswordRequired = false;
    this.isRequestByUser = false;
    this.lastPasswordChange = null;
    this.maxLifeDaysOfPassword = null;
    this.diffDays = null;
    this.daysRemaining = null;
    this.passwordUpdateAlertShowed = false;
    this.isAdminRole = false;
  }

  /**
   * @description Creates an instance with values of a user
   * @param {User} user - User to evaluate
   * @param {number} maxLifeDaysOfPassword - Max life days of password
   * @returns {ChangePasswordData} - Object mapped
   */
  public initChangePasswordDataByUser(user: User, maxLifeDaysOfPassword: number): ChangePasswordData {
    const changePasswordData: ChangePasswordData = new ChangePasswordData();
    changePasswordData.isChangePasswordRequired = user.isChangePasswordRequired;
    changePasswordData.lastPasswordChange = DateUtil.getThisMoment(user.lastPasswordChange);
    changePasswordData.isAdminRole = !isUndefined(
      find(user.roles, (role: Role) => role.isDefault && role.isActive)
    );
    changePasswordData.maxLifeDaysOfPassword = maxLifeDaysOfPassword;
    changePasswordData.calculateDaysRemaining();
    return changePasswordData;
  }

  /**
   * @description Calculates the days remaining to update the password
   * @returns {void}
   */
  public calculateDaysRemaining(): void {
    if (!isNil(this.lastPasswordChange)) {
      this.diffDays = DateUtil.getDateDifferenceInDays(
        this.lastPasswordChange.toDateString(),
        DateUtil.getThisMoment().toDateString()
      );
      this.diffDays = this.diffDays < CONSTANTS.ZERO
        ? CONSTANTS.ZERO : this.diffDays;
      if (this.maxLifeDaysOfPassword > CONSTANTS.ZERO) {
        this.daysRemaining = this.maxLifeDaysOfPassword - this.diffDays;
        this.daysRemaining = this.daysRemaining < CONSTANTS.ZERO
          ? CONSTANTS.ZERO : this.daysRemaining;
      } else {
        this.daysRemaining = Infinity;
      }
    }
  }
}
