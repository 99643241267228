import { Warehouse } from '../warehouse/warehouse';
import { AccountGroup } from './account-group';

export class Account {
  public id: number;
  public name: string;
  public description: string;
  public isActive: boolean;
  public lastChange: Date;
  public userId: number;
  public warehouses: Warehouse[];
  public accountGroups: AccountGroup[];
  public logoUrl: string;

  constructor() {
    this.id = undefined;
    this.name = '';
    this.description = '';
    this.isActive = false;
    this.userId = undefined;
    this.warehouses = [];
    this.accountGroups = [];
  }
}
