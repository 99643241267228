import { FootprintDetail } from '../../catalog/footprint/footprint-detail';
import { InventoryStatus } from '../../catalog/inventory-status/inventory-status';
import { Location } from '../../catalog/location/location';
import { Lpn } from '../../lpn/lpn';
import { Product } from '../../catalog/product/product';
import { Session } from '../../session/session';
import { ShipmentOrderDetail } from '../picking/shipment-order/shipment-order-detail';

export class TaskDetail {
  dynamicAttribute1: string;
  dynamicAttribute2: string;
  dynamicAttribute3: string;
  footprintDetail: FootprintDetail;
  id: number;
  inventoryRotation: string;
  inventoryStatus: InventoryStatus;
  lot: string;
  minimumShelfLife: number;
  orderedQuantity: number;
  originLocation: Location;
  originLpn: Lpn;
  pickedQuantity: number;
  product: Product;
  serial: string;
  session: Session;
  sku: string;
  taskHeaderId: number;
  time: number;
  userId: number;
  shipmentOrderDetails: ShipmentOrderDetail[];

  constructor() {
    this.dynamicAttribute1 = '';
    this.dynamicAttribute2 = '';
    this.dynamicAttribute3 = '';
    this.footprintDetail = new FootprintDetail();
    this.id = undefined;
    this.inventoryRotation = '';
    this.inventoryStatus = new InventoryStatus();
    this.lot = '';
    this.orderedQuantity = undefined;
    this.originLocation = new Location();
    this.originLpn = new Lpn();
    this.pickedQuantity = undefined;
    this.product = new Product();
    this.serial = '';
    this.session = new Session();
    this.sku = '';
    this.taskHeaderId = undefined;
    this.time = undefined;
    this.userId = undefined;
    this.shipmentOrderDetails = [];
  }
}
