import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ENVIRONMENT } from '../../../environments/environment';
import { HandleError } from '../../utils/utils';
import { Role } from '../role/role';
import { Session } from '../../session/session';
import { SessionService } from '../../session/session.service';
import { User } from './user';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient, private sessionService: SessionService) { }

  /**
   * @description This method adds an user.
   * @param {user} user user object to add.
   * @return {Observable<User>}
   */
  public addUser(user: User): Observable<User> {
    let session: Session = this.sessionService.getUserSession();

    let jsonObject: Object = {
      name: user.name,
      surname: user.surname,
      secondSurname: user.secondSurname,
      nickname: user.nickname,
      roles: user.roles,
      warehouseAccount: user.warehouseAccount,
      employeeNumber: user.employeeNumber,
      password: user.password,
      isActive: true,
      profile: user.profile,
      session: session
    };
    return this.http.post<User>(ENVIRONMENT.API + '/users', jsonObject)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method removes an user
   * @param {user} _user user  to delete
   * @return {Observable<User>}
   */
  public deleteUser(_user: User): Observable<User> {
    let session: Session = this.sessionService.getUserSession();
    return this.http.put<User>(ENVIRONMENT.API + '/users/' + _user.id, JSON.stringify(session))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method adds a role
   * @param {User} user old user to edit
   */
  public editUser(user: User): Observable<User> {
    let session: Session = this.sessionService.getUserSession();

    let jsonObject: Object = {
      id: user.id,
      name: user.name,
      surname: user.surname,
      secondSurname: user.secondSurname,
      nickname: user.nickname,
      password: user.password,
      isActive: true,
      employeeNumber: user.employeeNumber,
      roles: user.roles,
      warehouseAccount: user.warehouseAccount,
      session: session,
      profile: user.profile
    };
    return this.http.put<User>(ENVIRONMENT.API + '/users', jsonObject)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method returns all the Users from the backend
   * @return {Observable<User[]>}
   */
  public getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(ENVIRONMENT.API + '/users')
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Find all users for admin, in other case, found just the logged user
   * @return {Observable<User[]>}
   */
  public getAllUsersByRole(): Observable<User[]> {
    return this.http.get<User[]>(ENVIRONMENT.API + '/users/role')
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Searches for an user by its id
   * @param {number} id Number that contain the id about user.
   * @return {Observable<User>}
   */
  public getUserById(id: number): Observable<User> {
    return this.http.get<User>(ENVIRONMENT.API + '/users/' + id)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method obtains the user roles that exist in the system
   * @return {role[]} Arrangement that contains the roles that the system has or are discharged
   */
  public getUserRoles(rolesBackend: any[]): Role[] {
    let userRoles: Role[] = [];

    for (let role of rolesBackend) {
      let pushRole: Role = new Role();
      pushRole.name = role.name;
      pushRole.id = role.id;
      pushRole.isDefault = role.isDefault === 1;
      pushRole.description = role.description;
      userRoles.push(pushRole);
    }
    return userRoles;
  }

  /**
   * @description Gets the status for the nickname given
   * @param {string} nickname user's new nickname to check
   * @return {Observable<User>}
   */
  public isNicknameAvailable(nickname: string): Observable<User> {
    return this.http.get<User>(ENVIRONMENT.API + '/user/' + nickname + '/verify')
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method change the user status
   * @param {User} user - user to perform the lock or unlock action
   * @return {Observable<User>} containing the data according to the response
   */
  public changeUserStatus(user: User): Observable<User> {
    let session: Session = this.sessionService.getUserSession();
    let jsonObject: Object = {
      session: session
    };
    return this.http.put<User>(ENVIRONMENT.API + '/users/' + user.id + '/switch-block', jsonObject)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method updates user's password
   * @param {User} user user to update its password
   * @return {Observable<string>} containing the data according to the response
   */
  public updateUserPassword(user: User): Observable<string> {
    let session: Session = this.sessionService.getUserSession();
    let jsonObject: Object = {
      password: user.password,
      session: session
    };
    return this.http.put<string>(ENVIRONMENT.API + '/users/' + user.id + '/change-password', jsonObject)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method updates user's password
   * @param {User} user user to update its password
   * @return {Observable<string>} containing the data according to the response
   */
  public updateUserPasswordFirstAccess(user: User): Observable<string> {
    let session: Session = this.sessionService.getUserSession();
    let jsonObject: Object = {
      password: user.password,
      session: session
    };
    const userId: number = user.id || session.userId;
    return this.http.put<string>(ENVIRONMENT.API + '/users/' + userId + '/change-password/first-access', jsonObject)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Get all Actives users in RF
   * @returns {Observable<User[]>} Active users found
   */
  public getActiveUsersRf(): Observable<User[]> {
    return this.http.get<User[]>(ENVIRONMENT.API + '/users/active')
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Close session for the user selected
   * @param {User} selectedUser - Selected user
   * @returns {Observable<void>}
   */
  public closeSessionUser(user: User): Observable<void> {
    return this.http.post<void>(ENVIRONMENT.API + '/auth/close-session', user)
      .pipe(catchError(HandleError.handleErrorObservable));
  }
}
