import { Carousel } from 'primeng/carousel';
import { Component, EventEmitter, Input, Output, OnInit, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Observable, Subscriber, Observer } from 'rxjs';
import { SelectItem } from 'primeng/api/selectitem';
import { ScfToastrService } from 'scf-library';
import { share } from 'rxjs/operators';
import * as _ from 'lodash';

import { Footprint } from '../../catalog/footprint/footprint';
import { FootprintDetail } from '../../catalog/footprint/footprint-detail';
import { FootprintDetailRf } from '../../catalog/footprint/footprint-detail-rf';
import { FootprintWrapperService } from './footprint-wrapper.service';
import { FormUtils } from '../../utils/utils';
import { FormValidator } from '../custom-validators/form.validators';
import { InventoryDetail } from '../../inventory/inventory-detail';
import { KeyCode } from '../constants/keycode';
import { LabelService } from '../../administration/label-management/label.service';
import { MobileConfig } from '../constants/mobile-config';
import { PickingRf } from '../../process/rf/picking/picking-rf';
import { PickingRfService } from '../../process/rf/picking/picking-rf.service';
import { Product } from '../../catalog/product/product';
import { WepError } from '../wep-error';
import { CONSTANTS } from '../constants';

@Component({
  selector: 'app-footprint-wrapper',
  templateUrl: 'footprint-wrapper.component.html',
  styleUrls: ['./footprint-wrapper.component.css'],
  encapsulation: ViewEncapsulation.None
})

/**
 * Obtains and emit the selected footprint from carousel or dropdown.
 * @class FootprintWrapperComponent
 * @param {number} accountId - Account id related to the sku and footprints.
 * @param {number} locationId - Location id related to the sku and footprints.
 * @param {string} lpn - Lpn related to the sku and footprints.
 * @param {number} moduleId - Process Module Id.
 * @param {boolean} parameter - Parameter from the component, indicates if the dropdown should be visible.
 * @param {number} productId - Sku id to perform the search.
 * @param {string} sku - Sku to perform the search.
 * @param {carousel} carousel - Used to call and use carousel methods.
 * @param {number} footprintDetailId - If this value is not null, the wrapper only show this footprint detail
 * to confirm
 * @param {number} footprintId - If this value is not null, the wrapper shows the footprint details that
 * belongs to the sku and matches this id
 * @return {EventEmitter<Footprint>} select Selected Footprint object.
 */
export class FootprintWrapperComponent implements OnInit {
  @Input() accountId: number;
  @Input() autoSelectFootprint?: boolean;
  @Input() footprintDetailId?: number;
  @Input() footprintId?: number;
  @Input() locationId?: number;
  @Input() lpn?: string;
  @Input() moduleId: number;
  @Input() parameter: boolean;
  @Input() productId?: number;
  @Input() sku: string;

  @Output() carouselPage = new EventEmitter<FootprintDetail>();
  @Output() autocompleteChange = new EventEmitter<FootprintDetail>();
  @Output() footprintDetailNotFound = new EventEmitter<WepError>();

  @ViewChild('carousel', { static: false }) carousel: Carousel;

  public existingFootprints: Footprint[];
  public filteredFootprints: FootprintDetail[];
  public footprintConversionFactor: FootprintDetail;
  public footprintDetailsCarousel: FootprintDetail[];
  public footprintDetailsDropdown: SelectItem[];
  public footprintFormGroup: FormGroup;
  public footprintHeader: Footprint[];
  public footprintsSku: Footprint[];
  public formErrors: { [key: string]: string };
  public isHelpActive: boolean;
  public lbl: any = {};
  public loaded: boolean;
  public mobile: boolean;
  public msgs: any = {};
  public page: number;
  public previousPage: number;
  public placeholder: string;
  public product: Product;
  public selectedFootprintDetail: FootprintDetail;
  public selectedFootprintDetailId: number;
  public pageIndex: number;

  constructor(private footprintWrapperService: FootprintWrapperService,
    private labelService: LabelService,
    private pickingRfService: PickingRfService,
    private notifier: ScfToastrService,
    private formBuilder: FormBuilder) {
    this.existingFootprints = [];
    this.filteredFootprints = [];
    this.footprintDetailsCarousel = [];
    this.footprintDetailsDropdown = [];
    this.footprintHeader = [];
    this.footprintsSku = [];
    this.formErrors = {};
    this.isHelpActive = false;
    this.loaded = false;
    this.mobile = false;
    this.placeholder = this.lbl.selectFootprint;
    this.product = new Product();
    this.pageIndex = CONSTANTS.ZERO_QUANTITY;
    this.lbl = {
      confirmFootprint: this.labelService.labelText('confirmFootprint'),
      enter: this.labelService.labelText('rfNext'),
      enterGeneral: this.labelService.labelText('goNextStep'),
      escape: this.labelService.labelText('rfReturn'),
      escapeGeneral: this.labelService.labelText('returnPreviousStep'),
      f1: this.labelService.labelText('rfShowHelp'),
      f2: this.labelService.labelText('footprintWrapperInfoToUpdateNextConversionFactor'),
      f3: this.labelService.labelText('footprintWrapperInfoToUpdatePrevConversionFactor'),
      footprintTitle: this.labelService.labelText('footprintTitle'),
      help: this.labelService.labelText('helpTitle'),
      selectFootprint: this.labelService.labelText('selectFootprint'),
      shortcuts: this.labelService.labelText('shortcuts'),
      btns: {
        close: this.labelService.labelText('closeButton'),
        cancel: this.labelService.labelText('cancelButton'),
      },
    };
    this.msgs = {
      errorTitle: this.labelService.labelText('errorTitle')
    };
    this.instanceExistingInventory();
  }

   // Observable's
   private existingInventoryObservable: Observable<boolean>;
   private existingInventoryObserver: Observer<boolean>;

  /**
   * @description Listens for user's keys for custom functions
   */
  @HostListener('document:keyup', ['$event'])
  onkeyup(ev: KeyboardEvent) {
    // [Enter] key to select footprint detail
    if (_.isEqual(ev.key, KeyCode.Enter) && this.loaded) {
      this.confirmFootPrintDetail();
    }
  }
  @HostListener('document:keydown', ['$event'])
  onkeydown(ev: KeyboardEvent) {
    // [F1] key to show help dialog
    if (_.isEqual(ev.key, KeyCode.F1)) {
      ev.preventDefault();
      this.isHelpActive = true;
    }
    // [F2] key to show next footprint detail
    if (_.isEqual(ev.key, KeyCode.F2)) {
      ev.preventDefault();
      // TODO: VALIDATE IMPLEMENTATION
      // this.carousel.onNextNav();
    }
    // [F3] key to show previous footprint detail
    if (_.isEqual(ev.key, KeyCode.F3)) {
      ev.preventDefault();
      // TODO: VALIDATE IMPLEMENTATION
      // this.carousel.onPrevNav();
    }
    if (_.isEqual(ev.key, KeyCode.Enter)) {
      ev.preventDefault();
      // TODO: VALIDATE IMPLEMENTATION
      // this.carousel.onPrevNav();
    }
  }

  /**
   * @description Init component.
   * @return {void}
   */
  public ngOnInit(): void {
    this.initializeFormBuilder();
    this.footprintsInit();
    this.getFootprints();
  }

  /**
   * @description Method to instantiate the observables in the component.
   * @return {void}
   */
  private instanceExistingInventory(): void {
    this.existingInventoryObservable = new Observable(
      (observer: Subscriber<any>) => {
        this.existingInventoryObserver = observer;
      }).pipe(share());
    }

  /**
   * @description Initializes default options.
   * @return {void}
   */
  public footprintsInit(): void {
    this.accountId = _.isUndefined(this.accountId) ? null : this.accountId;
    this.locationId = _.isUndefined(this.locationId) ? null : this.locationId;
    this.lpn = _.isUndefined(this.lpn) ? null : this.lpn;
    this.moduleId = _.isUndefined(this.moduleId) ? null : this.moduleId;
    this.parameter = _.isUndefined(this.parameter) ? null : this.parameter;
    this.productId = _.isUndefined(this.productId) ? null : this.productId;
    this.sku = _.isUndefined(this.sku) ? null : this.sku;
    this.footprintId = _.isUndefined(this.footprintId) ? null : this.footprintId;
    this.footprintDetailId = _.isUndefined(this.footprintDetailId) ? null : this.footprintDetailId;
    this.autoSelectFootprint = _.isNil(this.autoSelectFootprint) ? false : this.autoSelectFootprint;

    if (window.screen.width < MobileConfig.WINDOW_WIDTH) {
      this.mobile = true;
    }
  }

  /**
   * @description This method initilizes the form with its properties
   * @return {void}
   */
  public initializeFormBuilder(): void {
    this.footprintFormGroup = this.formBuilder.group({
      footprintDetail: new FormControl('', [FormValidator.isValid(true)])
    });

    this.footprintFormGroup.valueChanges.subscribe((data: { [key: string]: Object }) => {
      this.formErrors = FormUtils.validateForm(this.footprintFormGroup, data, this.lbl.msgs);
    });
  }

  /**
   * @description Get related footprints based on sku and account
   * @return {void}
   */
  public getFootprints(): void {
    this.footprintWrapperService.getFootprints(this.sku, this.accountId).subscribe(
      (product: Product) => {
        this.footprintHeader = this.footprintsSku = product.footprints;

        if (_.isEqual(this.moduleId, CONSTANTS.PICKING_LIST_MODULE)) {
          this.getExistingInventory();
        } else {
          this.existingInventoryObserver.next(true);
        }
      },
      (error: WepError) => {
        this.notifier.errorAlert(this.labelService.getWepError(error.message));
      }
    );

    this.existingInventoryObservable.subscribe(successful => {
      if (this.parameter) {
        this.getFootprintDetails();
      } else {
        this.getFootprintDetailsCarousel();
      }
    });

  }

  /**
   * @description Get footprint details to fill carousel.
   * @return {void}
   */
  public getFootprintDetailsCarousel(): void {
    this.footprintDetailsCarousel = [];
    this.footprintWrapperService.getFootprintDetailsBySKU(this.sku, this.accountId).subscribe(
      (response: FootprintDetailRf[]) => {
        if (this.footprintId) {
          response = _.remove(response, {footprintId: this.footprintId});
        }
        if (this.footprintDetailId) {
          response = _.remove(response, {id: this.footprintDetailId});
        }
        let isPickingListModule = _.isEqual(this.moduleId, CONSTANTS.PICKING_LIST_MODULE);
        this.footprintDetailsCarousel = response;
        if (!isPickingListModule && !_.isEmpty(response)) {
          this.pageIndex = _.findIndex(this.footprintDetailsCarousel, ['isDefault', true]);
          this.selectedFootprintDetail = _.find(this.footprintDetailsCarousel, ['isDefault', true]);
          let footprintId: number = this.selectedFootprintDetail.footprintId;
          this.getFootprintConversionFactor(footprintId);
        }
        if (isPickingListModule) {
          this.footprintDetailsCarousel = [];
          _.forEach(this.footprintHeader, (footprintHeader: Footprint) => {
            this.filteredFootprints = _.filter(response, { 'footprintId': footprintHeader.id });
            _.map(this.filteredFootprints, (coin: FootprintDetail) => {
              this.footprintDetailsCarousel.push(coin);
            });
          });
        } else if (this.autoSelectFootprint && _.isEqual(this.footprintDetailsCarousel.length, CONSTANTS.ONE)) {
          this.confirmFootPrintDetail();
        }
      },
      (error: WepError) => {
        this.notifier.errorAlert(this.labelService.getWepError(error.message));
        this.footprintDetailNotFound.emit(error);
      },
      () => {
        this.loaded = true;
      }
    );
  }

  /**
   * @description Get footprint details to fill autocomplete
   * @return {void}
   */
  public getFootprintDetails(): void {
    this.footprintWrapperService.getFootprintDetailsBySKU(this.sku, this.accountId).subscribe(
      (footprints: FootprintDetailRf[]) => {

        if (this.footprintId) {
          footprints = _.remove(footprints, {footprintId: this.footprintId});
        }
        if (this.footprintDetailId) {
          footprints = _.remove(footprints, {id: this.footprintDetailId});
        }

        this.footprintDetailsCarousel = footprints;
        _.map(footprints, (detail: FootprintDetail) => {
          this.footprintDetailsDropdown.push({
            label: detail.description,
            value: detail.id
          });
          this.footprintDetailsDropdown = [...this.footprintDetailsDropdown];
        });

        if (_.isEqual(this.moduleId, CONSTANTS.PICKING_LIST_MODULE)) {
          this.footprintDetailsDropdown = [];
          _.forEach(this.footprintHeader, (footprintHeader: Footprint) => {
            this.filteredFootprints = _.filter(footprints, { 'footprintId': footprintHeader.id });
            _.map(this.filteredFootprints, (coin: FootprintDetail) => {
              this.footprintDetailsDropdown.push({
                label: coin.description,
                value: coin.id
              });
              this.footprintDetailsDropdown = [...this.footprintDetailsDropdown];
            });
          });
        }
      },
      (error: WepError) => {
        this.notifier.errorAlert(this.labelService.getWepError(error.message));
        this.footprintDetailNotFound.emit(error);
      }
    );
  }

  /**
   * @description Gets conversion factor for the selected footprint.
   * @param {number} footprintId - Footprint Id used for getting conversion factor.
   */
  public getFootprintConversionFactor(footprintId: number): void {
    this.footprintWrapperService.getDetailById(footprintId).subscribe(
      (footprints: FootprintDetail[]) => {
        let detail: any;
        detail = _.find(footprints, ['id', this.selectedFootprintDetail.id]);
        this.selectedFootprintDetail.conversionFactor = detail.conversionFactor;
      },
      (error: WepError) => {
        this.notifier.errorAlert(this.labelService.getWepError(error.message));
      }

    );
  }

  /**
   * @description Get selected footprint and emits to parent component
   * @param {Any} Event - Receives two type of events, onPage, onChange
   * @return {void}
   */
  public selectFootprint(event: any): void {
    let footprintId: number;
    if (!_.isUndefined(event.page)) {
      this.previousPage = this.page;
      this.page = event.page;
      this.selectedFootprintDetail = this.footprintDetailsCarousel[this.page];
      footprintId = this.selectedFootprintDetail.footprintId;
      this.getFootprintConversionFactor(footprintId);
    } else {
      this.selectedFootprintDetail = _.find(this.footprintDetailsCarousel, ['id', event.value]);
      footprintId = this.selectedFootprintDetail.footprintId;
      this.getFootprintConversionFactor(footprintId);
      this.loaded = false;
      this.autocompleteChange.emit(this.selectedFootprintDetail);
    }
  }

  /**
   * @memberof FootprintWrapperComponent
   * @description Change the conversion factor when the carousel select other that the user does not wants when he click enter without
   * deselect the carousel button
   * @return {void}
   */
  public selectPreviousConversionFactor(): void {
    let footprintId: number;
    this.page = this.previousPage;
    this.selectedFootprintDetail = this.footprintDetailsCarousel[this.page];
    footprintId = this.selectedFootprintDetail.footprintId;
    this.getFootprintConversionFactor(footprintId);
  }

  /**
   * @description Get existing footprints in location.
   * @return {void}
   */
  public getExistingInventory(): void {
    let pickingValidator: PickingRf = new PickingRf();
    pickingValidator.productId = this.productId;
    pickingValidator.originHeader.lpn.lpn = this.lpn;
    pickingValidator.originHeader.locationId = this.locationId;

    this.pickingRfService.validateInventory(pickingValidator).subscribe(
      (inventoryDetail: InventoryDetail[]) => {
        let tempFootprints: Footprint[] = [];
        _.forEach(inventoryDetail, (detail: InventoryDetail) => {
          tempFootprints.push(detail.footprint);
        });
        this.footprintHeader = _.intersectionBy(this.footprintHeader, tempFootprints, 'id');
        this.existingInventoryObserver.next(true);
      },
      (error: WepError) => {
        this.notifier.errorAlert(this.labelService.getWepError(error.message));
      },
    );
  }

  /**
   * @description confirm the footprint selected
   * @returns {void}
   */
  public confirmFootPrintDetail(): void {
    if (_.isUndefined(this.selectedFootprintDetail)) {
      this.selectedFootprintDetail = _.head(this.footprintDetailsCarousel);
    }
    this.carouselPage.emit(this.selectedFootprintDetail);
  }
}
