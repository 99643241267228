import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import * as _ from 'lodash';

/**
 * Number validator's params
 *  min: Check if value is less than min value
 *  max: Check if value is greater than max value
 *  decimal: Accept or not decimal numbers
 *  negative: Accept or not negative values
 *  zero: Accept or not zero values
 */
export interface NumberValidators {
  min?: number;
  max?: number;
  decimal?: boolean;
  negative?: boolean;
  zero?: boolean;
}

export interface RegExpValidators {
  key: string;
  regExp: RegExp;
}

/* Form Validators Class */
export class FormValidator {
  /**
   * @description Check if required field is not empty or if has only spaces
   * @param {boolean} isRequired True if field is required
   * @return if field is valid
   */
  static isValid(isRequired: boolean = false): ValidatorFn {
    return (control: FormControl): {[key: string]: any} => {
      let validation: Object = null;
      let value: any = control.value;

      if (isRequired) {
        validation = Validators.required(control);
      }

      if (_.isEmpty(validation) && typeof value === 'string' &&
        !_.isEmpty(value) && _.isEmpty(value.trim())) {
        validation = {
          invalid: true,
          emptyString: true
        };
      }
      return validation;
    };
  }

  /**
   * @description Check if number is valid by diferent params passed by parameters
   * @param {string} fieldName Field name t apply the validations
   * @param {NumberValidators} params Number applied restrictions
   *  First check if number is required and if its a valid number and then the params NumberValidators
   * @return if number is valid
   */
  static numberValidation(fieldName: string, params?: NumberValidators): ValidatorFn {
    return (control: FormControl): {[key: string]: any} => {
      if (Validators.required(control)) {
        return null;
      }

      let val: number = control.value;
      let validation: Object = null;
      let error: {[key: string]: string | boolean | number};
      error = {
        [fieldName]: fieldName,
        'number': true,
        'min': params.min,
        'max': params.max,
        'decimal': params.decimal,
        'negative': params.negative,
        'zero': params.zero
      };

      if (isNaN(val) || !/^(\-)?[0-9]+(\.[0-9]+)?$/.test(control.value)) {
        return error;
      }

      if (!params.negative) {
        validation = val < 0 ? error : null;
      }

      if (!validation && !params.zero) {
        validation = val === 0 ? error : null;
      }

      if (!validation && !params.decimal) {
        validation = val % 1 !== 0 ? error : null;
      }

      if (!validation && !isNaN(params.min) && !isNaN(params.max)) {
        validation = (val >= params.min && val <= params.max) ? null : error;
      }

      if (!validation && !isNaN(params.min)) {
        validation = val < params.min ? error : null;
      }

      if (!validation && !isNaN(params.max)) {
        validation = val > params.max ? error : null;
      }
      return validation;
    };
  }

  /**
   * @description Validates if field match with expression
   * @param {RegExpValidators} validators - Object with validations to consider
   * @returns {ValidatorFn} - If field match with expression
   */
  public static match(validators: RegExpValidators): ValidatorFn {
    return (control: FormControl): {[key: string]: any} => {
      if (Validators.required(control)) {
        return null;
      }
      const value: string = control.value;
      let validation: Object = null;
      if (!_.isNil(value) && !validators.regExp.test(value)) {
        validation = { [validators.key]: true };
      }
      return validation;
    };
  }
}
