import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { Area } from '../area/area';
import { CONSTANTS } from '../../shared/constants';
import { Criteria } from '../../utils/criteria/criteria';
import { ENVIRONMENT } from '../../../environments/environment';
import { HandleError } from '../../utils/utils';
import { InterfaceEnum } from '../../shared/interface-enum';
import { Location } from './location';
import { RelocateProductPut } from '../../process/reallocate/reallocate-product/reallocate-product';
import { WorkZone } from '../work-zone/work-zone';

@Injectable({ providedIn: 'root' })
export class LocationService {

  constructor(private http: HttpClient) {}

  /**
   * @description Class set method
   * @param {Location} location Object with the properties of location.
   * @return {void}
   */
  public setLocation(location: Location): void {
    localStorage.setItem('location', JSON.stringify(location));
  }

  /**
   * @description Class get method
   * @return {Location}
   */
  public getLocation(): Location {
    return JSON.parse(localStorage.getItem('location'));
  }

  /**
   * @description Location list set method
   * @param {Location[]} location List of locations
   * @return {void}
   */
  public setLocations(locations: Location[]): void {
    localStorage.setItem('locations', JSON.stringify(locations));
  }

  /**
   * @description Location list get method
   * @returns {Location[]} List of locations
   */
 public getLocations(): Location[] {
   let locations: Location[];
   locations = JSON.parse(localStorage.getItem('locations'));
   return locations;
  }

  /**
   * @description Area's set method
   * @param {Area[]} areas List of areas
   * @return {void}
   */
  public setAreas(areas: Area[]): void {
    localStorage.setItem('locationAreas', JSON.stringify(areas));
  }

  /**
   * @description Area's get method
   * @returns {Area[]} List of areas
   */
  public getAreas(): Area[] {
    let areas: Area[];
    areas = JSON.parse(localStorage.getItem('locationAreas'));
    return areas;
  }

  /**
   * @description WorkZone's set method
   * @param {WorkZone[]} workZone List of work zones
   * @return {void}
   */
  public setWorkZones(workZones: WorkZone[]): void {
    localStorage.setItem('locationWorkZones', JSON.stringify(workZones));
  }

  /**
   * @description WorkZone's get method
   * @returns {WorkZone[]} List of work zones
   */
  public getWorkZones(): WorkZone[] {
    let workZones: WorkZone[];
    workZones = JSON.parse(localStorage.getItem('locationWorkZones'));
    return workZones;
  }

  /**
   * @description LocationAccess's set method
   * @param {any[]} locationAccess Object with the properties of any value.
   * @return {void}
   */
  public setLocationAccess(locationAccess: any[]): void {
    localStorage.setItem('LocationAccess', JSON.stringify(locationAccess));
  }

  /**
   * @description LocationAccess's get method
   * @returns {any[]}
   */
  public getLocationAccess(): any[] {
    let locationAccess: any[];
    locationAccess = JSON.parse(localStorage.getItem('LocationAccess'));
    return locationAccess;
  }

  /**
   * @description Locations Selected set method
   * @param {Location} location Object with the properties of location.
   * @return {void}
   */
  public setLocationsList(locations: Location[]): void {
    localStorage.setItem('locationsSelected', JSON.stringify(locations));
  }

  /**
   * @description Locations Selected get method
   * @returns {Location[]}
   */
  public getListLocations(): Location[] {
    let locations: Location[];
    locations = JSON.parse(localStorage.getItem('locationsSelected'));
    return locations;
  }

  /**
   * @description Location's Access name set method
   * @param {any[]} locationAccess Location Access List
   * @param {any[]} locationAccessEnum
   */
  public buildLocationAccessEnum(locationAccess: any[]): any[] {
    let locationAccessEnum: any[] = [];

    locationAccess.forEach((row: Location) => {
        locationAccessEnum[row.id] = row.name;
    });

    localStorage.setItem('locationAccessEnum', JSON.stringify(locationAccessEnum));

    return locationAccessEnum;
  }

  /**
   * @description Locations Selected get method
   * @return {any[]}
   */
  public getLocationAccessEnum(): any[] {
    let locationAccessEnum: any;
    locationAccessEnum = JSON.parse(localStorage.getItem('locationAccessEnum'));
    return locationAccessEnum;
  }

  /**
   * @description Gets the list of locations
   * @param {any} filters Filter's object
   * @return {Observable<Location[]>} List of Locations
   */
  public getLocationsInfo(filters?: any): Observable<Location[]> {
    let criteria: Criteria = new Criteria;
    if (filters) {
      if (filters.aisle) {
        criteria.addFilterWhere('aisle', filters.aisle, 'like');
      }
      if (filters.side) {
        criteria.addFilterWhere('side', filters.side, 'like');
      }
      if (filters.back) {
        criteria.addFilterWhere('back', filters.back, 'like');
      }
      if (filters.level) {
        criteria.addFilterWhere('level', filters.level, 'like');
      }
      if (filters.locationId) {
        criteria.addFilterWhere('loc.id', filters.locationId, '=');
      }
    }

    let locationsInfoObservable: Observable<Location[]>;
    locationsInfoObservable = this.http.get<Location[]>(ENVIRONMENT.API + '/locations?graphql={}&criteria=' +
      criteria.stringify()).pipe(catchError(HandleError.handleErrorObservable));
    return locationsInfoObservable;
  }

  /**
   * @description Gets the list of areas
   * @return {Observable<Area[]>} List of Areas
   */
  public getAreasInfo(): Observable<Area[]> {
    let areaObservable: Observable<Area[]>;
    areaObservable = this.http.get<Area[]>(ENVIRONMENT.API + '/areas')
      .pipe(catchError(HandleError.handleErrorObservable));
    return areaObservable;
  }

  /**
   * @description Gets the list of work zones
   * @return {Observable<WorkZone[]>} List of Work Zones
   */
  public getWorkZonesInfo(): Observable<WorkZone[]> {
    let getWorkZonesInfoObservable: Observable<WorkZone[]>;
    getWorkZonesInfoObservable = this.http.get<WorkZone[]>(ENVIRONMENT.API + '/workzones')
      .pipe(catchError(HandleError.handleErrorObservable));
    return getWorkZonesInfoObservable;
  }

  /**
   * @description Gets the list of location's access
   * @return {Observable<InterfaceEnum[]>} List of Location Access
   */
  public getLocationAccessInfo(): Observable<InterfaceEnum[]> {
    let locationAccessInfoObservable: Observable<any[]>;
    locationAccessInfoObservable = this.http.get<InterfaceEnum[]>(ENVIRONMENT.API +
      '/locations/findAll/locationAccess').pipe(catchError(HandleError.handleErrorObservable));
    return locationAccessInfoObservable;
  }

  /**
   * @description Gets an location by its id
   * @param {number} locationId Location Id
   * @return {Promise<any>} Location information
   */
  public getLocationById(locationId: number): Promise<any> {
    let locations = this.getLocations();

    let location: Location;
    locations.forEach((item: any) => {
      if (item.id === locationId) {
        location = item;
      }
    });
    return Promise.resolve(JSON.stringify({status: 'success', data: location}));
  }

  /**
   * @description Gets an location by name
   * @param {string} locationName Location name
   * @param {any} filters - Filters to appy
   * @return {Observable<Location>} Location information
   */
  public getLocationByName(locationName: string, filters?: any): Observable<Location> {
    let criteria: any[] = [];
    if (filters) {
      if (filters.userId) {
        criteria.push({ field: 'assignment_zone_user.user_id', condition: '=', value: filters.userId });
      }
    }
    return this.http.get<Location>(ENVIRONMENT.API + '/locations/' + locationName + '?criteria=' + JSON.stringify(criteria))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Saves the Location Information
   * @param {Location} location Location's object
   * @return {Observable<number>}
   */
  public saveLocation(location: Location): Observable<number> {
    let anyLocation: any = location;
    anyLocation.area = location.area;
    anyLocation.workZone = location.workZone;

    if (anyLocation.id) {
      return this.http.put<number>(ENVIRONMENT.API + '/locations', anyLocation)
        .pipe(catchError(HandleError.handleErrorObservable));
    } else {
      return this.http.post<number>(ENVIRONMENT.API + '/locations', anyLocation)
        .pipe(catchError(HandleError.handleErrorObservable));
    }
  }

  /**
   * @description Deletes the current location
   * @param {Location} location Location's object
   * @returns {Observable<Location>}
   */
  public deleteLocation(location: Location): Observable<Location> {
    return this.http.put<Location>(ENVIRONMENT.API + '/locations/' + location.id, null)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Returns a picking location which match with name given
   * @param {string} location Location's object
   * @returns {Observable<Location>}
   */
  public findPickingLocation(location: string): Observable<Location> {
    return this.http.get<Location>(ENVIRONMENT.API + '/locations/picking-location/' + location)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description find locations by area type and criteria filters
   * @param {string} areaType Area type to find locations
   * @param {Object[]} criteria Filters to apply
   * @return {Observable<Location[]>} Locations found
   */
  public findLocationsByAreaType(areaType: string, criteria?: Object[]): Observable<Location[]> {
    let criteriaRoute: string;
    if (!_.isUndefined(criteria)) {
      criteriaRoute = '?criteria=' + JSON.stringify(criteria);
    }
    return this.http.get<Location[]>(ENVIRONMENT.API + '/locations/areaType/' + areaType + criteriaRoute)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description find empty locations by warehouse
   * @return {Observable<Location[]>} Empty locations found
   */
  public findEmptyLocations(): Observable<Location[]> {
    let criteria: Object[] = [];
    criteria.push({ field: 'used_capacity', condition: '=', value: CONSTANTS.ZERO });
    return this.http.get<Location[]>(ENVIRONMENT.API + '/locations?criteria=' + JSON.stringify(criteria))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Verify location rulers allow
   * @param {Location} location Location's object
   * @param {RelocateProductPut} relocateProduct RelocateProductPut's object
   * @returns {Observable<void>}
   */
   public evaluateLocationRules(locationId: number, relocateProduct: RelocateProductPut): Observable<void> {
    return this.http.post<void>(ENVIRONMENT.API + '/inventory/reallocate/evaluate-location-rules?locationId=' + locationId, relocateProduct)
    .pipe(catchError(HandleError.handleErrorObservable));
  }
}
