import { Account } from '../account/account';
import { AlterNumber } from './alter-number';
import { Footprint } from '../footprint/footprint';
import { HazardousMaterialKey } from '../../utils/integrations/catalogs/hazardousMaterialKey';
import { InventoryRotation } from './inventory-rotation';
import { Subfamily } from '../subfamily/subfamily';
import { PackingKey } from '../../utils/integrations/catalogs/packingKey';

export class Product {
  public account: Account;
  public category: string;
  public checkNumber: string;
  public footprints: Footprint[];
  public id: number;
  public inventoryRotation: InventoryRotation;
  public isActive: boolean;
  public isDynamicAttribute1: boolean;
  public isDynamicAttribute2: boolean;
  public isDynamicAttribute3: boolean;
  public isEnabled: boolean;
  public isImportNumber: boolean;
  public isKit: boolean;
  public isLot: boolean;
  public isRequiredExpiration: boolean;
  public isSerial: boolean;
  public largeDescription: string;
  public lastChange: Date;
  public lotDaysInterval: number;
  public name: string;
  public productAlterNumbers: AlterNumber[];
  public requireInspection: boolean;
  public satKey: string;
  public sendInterface: boolean;
  public shortDescription: string;
  public sku: string;
  public subfamily: Subfamily;
  public userId: number;
  // custom fields
  public accountName: string;
  public allowedPercentageReceive?: number;
  public allowedQuantity?: number;
  public defaultLifeDays: number;
  public hazardousMaterialKey: HazardousMaterialKey | string;
  public isHazardousMaterial: boolean;
  public orderId: number;
  public packingKey: PackingKey | string;
  public packingKeyDescription: string;
  public subfamilyName: string;

  constructor() {
    this.account = null;
    this.productAlterNumbers = null;
    this.category = null;
    this.checkNumber = null;
    this.footprints = null;
    this.id = null;
    this.inventoryRotation = null;
    this.isActive = null;
    this.isDynamicAttribute1 = null;
    this.isDynamicAttribute2 = null;
    this.isDynamicAttribute3 = null;
    this.isEnabled = null;
    this.isImportNumber = null;
    this.isKit = null;
    this.isLot = null;
    this.isRequiredExpiration = null;
    this.isSerial = null;
    this.largeDescription = null;
    this.lastChange = null;
    this.lotDaysInterval = null;
    this.name = null;
    this.requireInspection = null;
    this.satKey = null;
    this.sendInterface = null;
    this.shortDescription = null;
    this.sku = null;
    this.subfamily = null;
    this.userId = null;
    // custom fields
    this.subfamilyName = null;
    this.accountName = null;
    this.defaultLifeDays = 0;
    this.allowedQuantity = 0;
    this.orderId = null;
    this.isHazardousMaterial = null;
    this.hazardousMaterialKey = null;
    this.packingKey = null;
    this.packingKeyDescription = null;
  }
}
